import React, { useMemo } from 'react';

import { AnalyticsContext } from '@atlaskit/analytics-next';

import { useOnDismissDialog } from '../../../analytics/use-on-dismiss-dialog';
import { useEditionAwareness } from '../../../services/edition-awareness-context';
import { getChargeElementKey } from '../../../services/get-charge-element-key';
import { getJiraApplicationKey } from '../../../services/get-jira-application-key';
import { getJiraProjectTypeKey } from '../../../services/get-jira-project-type-key';
import { getHamsKey } from '../../../services/product-to-hams-key';
import { ModalEntryPoint } from '../shared/modal-entry-point';

import { DIALOG_CONFIG } from './dialog-config';
import { entryPoint, EntryPointParams } from './entrypoint';

export function StandardAddPayment() {
	const { product, cloudId } = useEditionAwareness();

	const projectType = getJiraProjectTypeKey(product);
	const jiraApplicationKey = getJiraApplicationKey(product);

	if (!projectType || !jiraApplicationKey) {
		throw new Error('Tried to render free to standard refresh dialog for unsupported product');
	}

	const entryPointParams: EntryPointParams = useMemo(
		() => ({
			cloudId,
			hamsProductKey: getHamsKey(product),
			chargeElement: getChargeElementKey(product),
			projectType,
			jiraApplicationKey,
		}),
		[cloudId, product, projectType, jiraApplicationKey],
	);

	const onClose = useOnDismissDialog({ ...DIALOG_CONFIG });

	const entryPointProps = useMemo(() => ({ onClose, product }), [onClose, product]);

	const modalProps = useMemo(() => ({ width: 968, height: 576, autoFocus: false }), []);

	return (
		<AnalyticsContext data={DIALOG_CONFIG}>
			<ModalEntryPoint
				entryPointProps={entryPointProps}
				entryPoint={entryPoint}
				entryPointParams={entryPointParams}
				modalProps={modalProps}
			/>
		</AnalyticsContext>
	);
}
