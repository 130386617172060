import React from 'react';

import { type EntryPoint, type EntryPointComponent } from 'react-relay';

import { ModalContextProvider } from '@atlassian/entry-point-modal-context/ModalContextProvider';
import { ModalTrigger, type Props } from '@atlassian/entry-point-modal-trigger';

import { useEntrypointErrorAnalytics } from '../../../analytics/use-entrypoint-error-analytics';
import { EditionAwarenessButton } from '../../edition-awareness-button';

type AnyEntryPoint = EntryPoint<EntryPointComponent<any, any, any, any>, any>;

export function ModalEntryPoint<TEntryPoint extends AnyEntryPoint>(
	props: Omit<Props<TEntryPoint>, 'children'>,
) {
	const onError = useEntrypointErrorAnalytics();

	return (
		<ModalContextProvider>
			<ModalTrigger {...props} onError={onError}>
				{({ ref }) => {
					return <EditionAwarenessButton ref={ref as unknown as React.Ref<HTMLButtonElement>} />;
				}}
			</ModalTrigger>
		</ModalContextProvider>
	);
}
