import { ErrorInfo, useCallback } from 'react';

import { useAnalytics } from './use-analytics';

/**
 * Only use this hook for entrypoint errors.
 * Product, Edition and Dialog Config are added to the analytics event through the context.
 */
export function useEntrypointErrorAnalytics() {
	const { sendAnalyticsEvent } = useAnalytics();
	const handleOnError = useCallback(
		({ error, errorInfo }: { error: Error; errorInfo: ErrorInfo }) => {
			sendAnalyticsEvent({
				eventType: 'operational',
				action: 'failed',
				actionSubject: '@atlassian/edition-awareness.edition-awareness',
				attributes: {
					error: error.message,
					errorStack: error.stack,
					errorName: error.name,
					errorComponentStack: errorInfo.componentStack,
				},
			});
		},
		[sendAnalyticsEvent],
	);

	return handleOnError;
}
