import React, { useMemo } from 'react';

import { AnalyticsContext } from '@atlaskit/analytics-next';

import { useOnDismissDialog } from '../../../analytics/use-on-dismiss-dialog';
import { useEditionAwareness } from '../../../services/edition-awareness-context';
import { getHamsKey } from '../../../services/product-to-hams-key';
import { DismissibleModalEntryPoint } from '../shared/dismissible-modal-entry-point';

import { DIALOG_CONFIG } from './dialog-config';
import { entryPoint } from './entrypoint';

export function StandardToPremiumDialog() {
	const { product, cloudId } = useEditionAwareness();

	const entryPointParams = useMemo(
		() => ({ cloudId, hamsProductKey: getHamsKey(product) }),
		[cloudId, product],
	);

	const onClose = useOnDismissDialog({ ...DIALOG_CONFIG });
	const entryPointProps = useMemo(() => ({ onClose }), [onClose]);

	return (
		<AnalyticsContext data={DIALOG_CONFIG}>
			<DismissibleModalEntryPoint
				entryPoint={entryPoint}
				entryPointProps={entryPointProps}
				entryPointParams={entryPointParams}
			/>
		</AnalyticsContext>
	);
}
