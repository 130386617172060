import React, { useCallback } from 'react';

import { FormattedMessage } from 'react-intl-next';

import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import Link from '@atlaskit/link';
import { Box, Pressable, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	ACTEvent,
	AdControlDropdownMenu,
	AdControlProvider,
	DismissalButton,
	ReasonsButton,
} from '@atlassian/ad-control-toolkit';

import { useOnClickDismissMenu } from '../analytics/use-on-click-dismiss-menu';
import { useEditionAwareness } from '../services/edition-awareness-context';
import { getTrialOfferingName } from '../services/get-trial-offering-name';

const dismissMenuStyles = xcss({
	borderWidth: 'border.width',
	font: 'font.body',
	fontWeight: 'font.weight.medium',
	borderStyle: 'solid',
	backgroundColor: 'elevation.surface',
	borderRadius: 'border.radius.100',
	paddingInline: 'space.100',
	paddingBlock: '0',
	minHeight: '32px',
	color: 'color.text',
	transitionProperty: 'color, background-color',
	transitionDuration: '200ms',
	display: 'flex',
	gap: 'space.100',
	alignItems: 'center',
	textDecoration: 'none',
	borderColor: 'color.border.discovery',
	borderLeftWidth: '0',
	borderTopLeftRadius: '0',
	borderBottomLeftRadius: '0',

	':hover': {
		backgroundColor: 'color.background.accent.purple.subtlest.hovered',
	},

	':active': {
		backgroundColor: 'color.background.accent.purple.subtlest.pressed',
	},
});

const reasonsUlStyles = xcss({
	marginTop: '0',
	marginLeft: 'space.200',
	paddingLeft: 'space.100',
});

const capitalizeName = (name: string) => name.charAt(0).toUpperCase() + name.slice(1);

export function DismissMenu() {
	const fireDismissMenuEvent = useOnClickDismissMenu();
	const { product, edition, config, onDismissButton, handleSetSpotlight } = useEditionAwareness();

	const trialOfferingName = getTrialOfferingName(edition, config);

	const handleOnEvents = useCallback(
		(e: ACTEvent) => {
			if (e.type === 'DISMISS_SUCCEEDED' && onDismissButton) {
				onDismissButton();
				fireDismissMenuEvent('dismiss');
				handleSetSpotlight?.(true);
			}

			if (e.type === 'AD_CONTROL_DROP_DOWN_MENU_OPENED') {
				fireDismissMenuEvent('openMenu');
			}

			if (e.type === 'REASONS_BUTTON_CLICKED') {
				fireDismissMenuEvent('learnMore');
			}
		},
		[onDismissButton, handleSetSpotlight, fireDismissMenuEvent],
	);

	const getAdControlsTrigger = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		({ triggerRef, ...props }: any) => (
			<Pressable {...props} ref={triggerRef} xcss={[dismissMenuStyles]} aria-label="More">
				<ShowMoreHorizontalIcon label="" spacing="none" color={token('color.icon.subtlest')} />
			</Pressable>
		),
		[],
	);

	const offering = (
		<Text weight="bold">
			{capitalizeName(product)} {capitalizeName(trialOfferingName)}
		</Text>
	);

	return (
		<AdControlProvider onEvents={(e) => handleOnEvents(e)} shouldShowDismissFlag={false}>
			<AdControlDropdownMenu itemId="default-item" trigger={getAdControlsTrigger}>
				{(props) => (
					<>
						<DismissalButton {...props}>Dismiss</DismissalButton>
						<ReasonsButton
							{...props}
							modalProps={{
								body: (
									<Stack space="space.150">
										<Box>
											<FormattedMessage
												id="@atlassian/edition-awareness.edition-awareness-dismiss-button.why-am-i-seeing-this"
												defaultMessage="We think {offering} will improve your team's productivity. This recommendation is only shown to billing admins and is based on:"
												description="A message that informs the user why they are seeing the current plan recommendation for their current product"
												values={{
													offering,
												}}
											/>
										</Box>

										<Stack as="ul" xcss={reasonsUlStyles}>
											<li>
												<FormattedMessage
													id="@atlassian/edition-awareness.edition-awareness-dismiss-button.reason-1"
													defaultMessage="the size of your team"
													description="A reason why the user is seeing the current plan recommendation"
												/>
											</li>
											<li>
												<FormattedMessage
													id="@atlassian/edition-awareness.edition-awareness-dismiss-button.reason-2"
													defaultMessage="your existing Atlassian products"
													description="A reason why the user is seeing the current plan recommendation"
												/>
											</li>
										</Stack>

										<Box>
											<Link
												appearance="default"
												href="https://www.atlassian.com/legal/privacy-policy#what-this-policy-covers"
												target="_blank"
												onClick={() => fireDismissMenuEvent('clickLink')}
											>
												<FormattedMessage
													id="@atlassian/edition-awareness.edition-awareness-dismiss-button.data-privacy.link"
													defaultMessage="Learn more about your data privacy with Atlassian"
													description="Link to Atlassian data privacy page"
												/>
											</Link>
										</Box>
									</Stack>
								),
							}}
						/>
					</>
				)}
			</AdControlDropdownMenu>
		</AdControlProvider>
	);
}
