/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/* eslint-disable */
import React from 'react';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import { css, jsx } from '@emotion/react';
import {
	analyticsAttributes,
	getItemAnalyticsContext,
	NavigationAnalyticsContext,
	withAnalyticsContextData,
} from '../../common/utils/analytics';
import { FadeIn } from './fade-in';
import { type SwitcherItemType } from '../../common/utils/links';
import ItemLink from './item-link';
import { AnalyticsItemType } from '../../types';
import Heading from '@atlaskit/heading';
import { useIsSwitcherTeam25TestCohort } from '../components/experiment-provider';

const baseSectionContainerStyles = css({
	padding: `${token('space.075', '6px')} 0`,
});

const visualRefreshStyles = css({
	paddingTop: token('space.100'),
	overflow: 'hidden',
	marginTop: '-1px',
	borderTop: `1px solid ${token('color.border')}`,
});

const boxStyles = xcss({
	marginLeft: 'space.100',
	marginRight: 'space.100',
	marginTop: '0',
	marginBottom: 'space.100',
});

const secondarySectionTitleBaseStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textTransform: 'uppercase',
	whiteSpace: 'nowrap',
	marginTop: 'space.200',
	marginLeft: 'space.100',
	color: 'color.text.subtle',
	font: 'font.body.small',
	fontWeight: 'font.weight.bold',
	marginBottom: 'space.050',
});

const refreshedSecondarySectionTitleBaseStyles = xcss({
	marginTop: 'space.075',
	marginLeft: 'space.100',
	marginBottom: 'space.075',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	color: 'color.text.subtlest',
	fontWeight: 'font.weight.medium',
});

const sectionChildrenWrapperStyles = css({
	listStyleType: 'none',
	padding: 0,
	margin: 0,
});

type SectionProps = {
	sectionId: string;
	title: React.ReactNode;
	titleLink?: SwitcherItemType;
	children?: React.ReactNode;
	actionSubject?: string;
	sectionRole?: string;
	ariaLabel?: string;
};

type SectionAnalyticsContext = {
	attributes: {
		group: string;
		groupItemsCount: number;
	};
};

export const SectionContainerTitle = ({ title }: Pick<SectionProps, 'title'>) => {
	const isVisualRefreshEnabled = useIsSwitcherTeam25TestCohort();

	return (
		<Heading as="h3" size="xxsmall">
			<Box
				xcss={
					isVisualRefreshEnabled
						? refreshedSecondarySectionTitleBaseStyles
						: secondarySectionTitleBaseStyles
				}
			>
				{title}
			</Box>
		</Heading>
	);
};

const SectionContainerComponent = ({
	title,
	titleLink,
	children,
	actionSubject,
	sectionId,
	sectionRole,
	ariaLabel,
}: SectionProps) => {
	const isVisualRefreshEnabled = useIsSwitcherTeam25TestCohort();

	return React.Children.toArray(children).some(Boolean) ? (
		<section
			css={isVisualRefreshEnabled ? visualRefreshStyles : baseSectionContainerStyles}
			data-testid={`${sectionId}__section`}
			role={sectionRole}
			aria-label={ariaLabel}
		>
			<Box xcss={isVisualRefreshEnabled ? boxStyles : undefined}>
				<FadeIn>
					{title && <SectionContainerTitle title={title} />}
					{titleLink && <TitleLink titleLink={titleLink} actionSubject={actionSubject} />}
				</FadeIn>
				<ul css={sectionChildrenWrapperStyles}>{children}</ul>
			</Box>
		</section>
	) : null;
};

const TitleLink = ({
	titleLink,
	actionSubject,
}: {
	titleLink: SwitcherItemType;
	actionSubject?: string;
}) => (
	<NavigationAnalyticsContext
		key={titleLink.key}
		data={getItemAnalyticsContext(
			0,
			titleLink.key,
			AnalyticsItemType.PRODUCT,
			titleLink.productType,
		)}
	>
		<ItemLink
			href={titleLink.href}
			iconAfter={<ShortcutIcon LEGACY_size="small" color="currentColor" label="" />}
			label={titleLink.label}
			testId="section-title__link"
			actionSubject={actionSubject}
			ariaLabel={titleLink.ariaLabel}
		/>
	</NavigationAnalyticsContext>
);
export const SectionContainer = withAnalyticsContextData<SectionProps, SectionAnalyticsContext>(
	(props) =>
		analyticsAttributes({
			group: props.sectionId,
			groupItemsCount: React.Children.toArray(props.children).filter((child) =>
				React.isValidElement(child),
			).length,
		}),
)(SectionContainerComponent);

/* eslint-enable */
