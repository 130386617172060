import React, { useMemo } from 'react';

import { AnalyticsContext } from '@atlaskit/analytics-next';

import { useOnDismissDialog } from '../../../analytics/use-on-dismiss-dialog';
import { useEditionAwareness } from '../../../services/edition-awareness-context';
import { getChargeElementKey } from '../../../services/get-charge-element-key';
import { getJiraApplicationKey } from '../../../services/get-jira-application-key';
import { getJiraProjectTypeKey } from '../../../services/get-jira-project-type-key';
import { getHamsKey } from '../../../services/product-to-hams-key';
import { ModalEntryPoint } from '../shared/modal-entry-point';

import { DIALOG_CONFIG } from './dialog-config';
import { entryPoint, EntryPointParams } from './entrypoint';

export function StandardTrialLimitBreakersDialog() {
	const { product, cloudId } = useEditionAwareness();

	const jiraApplicationKey = getJiraApplicationKey(product);
	const projectType = getJiraProjectTypeKey(product);

	const entryPointParams: EntryPointParams = useMemo(
		() => ({
			cloudId,
			hamsProductKey: getHamsKey(product),
			chargeElement: getChargeElementKey(product),
			jiraApplicationKey: jiraApplicationKey ?? 'JIRA_SOFTWARE', // Only supports Jira Software
			projectType: projectType ?? 'SOFTWARE', // Only supports Jira Software
			shouldIncludeIfProductIsConfluence: product === 'confluence',
			shouldIncludeIfProductIsJiraSoftware: product === 'jira',
		}),
		[cloudId, product, projectType, jiraApplicationKey],
	);

	const onClose = useOnDismissDialog({ ...DIALOG_CONFIG });
	const entryPointProps = useMemo(() => ({ onClose }), [onClose]);
	const modalProps = useMemo(() => ({ width: 'x-large', autoFocus: false }), []);

	return (
		<AnalyticsContext data={DIALOG_CONFIG}>
			<ModalEntryPoint
				entryPoint={entryPoint}
				entryPointProps={entryPointProps}
				entryPointParams={entryPointParams}
				modalProps={modalProps}
			/>
		</AnalyticsContext>
	);
}
