import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

import parameters, {
	type JiraApplicationKey,
	type JiraProjectType,
} from './__generated__/standardAddPaymentModalEditionAwarenessQuery.graphql';

export type EntryPointParams = {
	cloudId: string;
	hamsProductKey: string;
	projectType: JiraProjectType;
	chargeElement: string;
	jiraApplicationKey: JiraApplicationKey;
};

export const entryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "@atlaskit-internal_edition-awareness-standard-add-payment-modal" */ './standard-add-payment-modal'
			),
		{
			moduleId: 'edition-awareness-standard-add-payment-modal',
		},
	),
	getPreloadProps: ({
		cloudId,
		hamsProductKey,
		projectType,
		chargeElement,
		jiraApplicationKey,
	}: EntryPointParams) => ({
		entryPoints: {},
		extraProps: {},
		queries: {
			tenantContexts: {
				parameters,
				variables: {
					cloudId,
					hamsProductKey,
					chargeElement,
					projectType,
					jiraApplicationKey,
				},
			},
		},
	}),
});
