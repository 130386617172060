import React, { type ErrorInfo, type ReactElement, type ReactNode, useCallback } from 'react';

import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';
import { EntryPointContainer, type PreloadedEntryPoint } from 'react-relay';

import { reportError } from '@atlaskit/report-error';
import { useEntryPointConfig } from '@atlassian/entry-point-config';
import type { GetRuntimePropsFromEntryPointReference } from '@atlassian/entry-point-types';

type OnError = (error: Error, info: ErrorInfo) => void;
export type ErrorFallback = (props: { error: Error }) => ReactElement<any, any> | null;
export type ErrorCallback = (props: { error: Error; errorInfo: ErrorInfo }) => void;

export type Props<TPreloadedEntryPoint> = {
	entryPointReference: TPreloadedEntryPoint;
	errorFallback?: ErrorFallback;
	onError?: ErrorCallback;
	fallback: ReactNode;
	id: string;
	placeholderName?: string;
	runtimeProps: GetRuntimePropsFromEntryPointReference<TPreloadedEntryPoint>;
};

export function InternalEntryPointContainer<TPreloadedEntryPoint extends PreloadedEntryPoint<any>>({
	entryPointReference,
	errorFallback,
	/** Log and track errors */
	onError: onErrorLocal,
	/** Loading fallback */
	fallback,
	id,
	placeholderName,
	runtimeProps,
}: Props<TPreloadedEntryPoint>) {
	const { PlaceholderComponent, UFOSegmentComponent } = useEntryPointConfig();

	const onError = useCallback<OnError>(
		(error, errorInfo) => {
			onErrorLocal?.({ error, errorInfo });
			reportError({
				error,
				errorInfo,
				interactionId: id,
			});
		},
		[onErrorLocal, id],
	);

	const FallbackComponent = useCallback(
		({ error }: FallbackProps) => {
			return errorFallback ? errorFallback({ error }) : null;
		},
		[errorFallback],
	);

	return (
		<UFOSegmentComponent name={id}>
			<ErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
				<PlaceholderComponent
					name={placeholderName ?? 'internal-internal-entry-point-container'}
					fallback={fallback}
				>
					<EntryPointContainer entryPointReference={entryPointReference} props={runtimeProps} />
				</PlaceholderComponent>
			</ErrorBoundary>
		</UFOSegmentComponent>
	);
}
