import { Product } from '../types';

const PRODUCT_TO_TRANSLATION_KEY: Record<Product, string> = {
	jira: 'jira',
	'jira-service-management': 'jsm',
	'jira-product-discovery': 'jpd',
	confluence: 'confluence',
};

/**
 * When translating with the product as the key you need to use plain text without dashes for the icuj4 format
 * to support select statements
 */
export function getProductForTranslation(product: Product) {
	return PRODUCT_TO_TRANSLATION_KEY[product];
}
