import { type IntlShape, useIntl } from 'react-intl-next';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { getProductForTranslation } from '../services/get-product-translation-key';
import type { ExperimentCohort, ExperimentCohortControlVariation, Product } from '../types';

import { EditionAwarenessContextType, useEditionAwareness } from './edition-awareness-context';
import { isEligibleForEduDiscount } from './is-eligible-for-edu-discount';
import { isEligibleForStandardToPremium } from './is-eligible-for-standard-to-premium';
import { isEligibleForStandardTrialsUserAndStorageLimitBreakers } from './is-eligible-for-standard-trials-user-and-storage-limit-breakers';

// TODO: `licensedUserLimit` should be fetched from the server
const PRODUCT_LIMITS_MAP: Record<Product, { licensedUserLimit: number; warningUserLimit: number }> =
	{
		'jira-product-discovery': {
			licensedUserLimit: 3,
			warningUserLimit: 2,
		},
		jira: {
			licensedUserLimit: 10,
			warningUserLimit: 7,
		},
		confluence: {
			licensedUserLimit: 10,
			warningUserLimit: 3,
		},
		'jira-service-management': {
			licensedUserLimit: 3,
			warningUserLimit: 2,
		},
	};

export type IconType = 'gem' | 'warning' | 'credit-card';
export type Appearance = 'default' | 'warning' | 'danger';

export type ButtonContent = {
	text: string;
	appearance: 'default' | 'warning' | 'danger';
	icon: IconType;
	isDismissible?: boolean;
	isEducationDiscount?: boolean;
};

function getFreeContent({
	licensedUsers,
	context,
	formatMessage,
}: {
	licensedUsers?: number | null;
	context: EditionAwarenessContextType;
	formatMessage: IntlShape['formatMessage'];
}): ButtonContent {
	const { edition, config, product } = context;

	const limits = PRODUCT_LIMITS_MAP[product];

	// Education offer has a higher priority than all the other messages
	if (
		isEligibleForEduDiscount(context) &&
		FeatureGates.getExperimentValue<ExperimentCohortControlVariation>(
			'offer_edu_org_discount',
			'cohort',
			'not-enrolled',
		) === 'variation'
	) {
		return {
			text: formatMessage({
				id: '@atlassian/edition-awareness.button.education-offer',
				defaultMessage: 'Education discount',
				description:
					'Informs the user that they may be are eligible for an education offer which is a discount on all products',
			}),
			appearance: 'default',
			icon: 'gem',
			isEducationDiscount: true,
		};
	}

	if (config.warnOnUserLimit && licensedUsers) {
		if (licensedUsers >= limits.licensedUserLimit) {
			return {
				text: formatMessage(
					{
						id: '@atlassian/edition-awareness.button.jpd.no-creators-left',
						defaultMessage:
							'No {product, select, jpd {creators} jsm {agents} jira {users} confluence {users} other {users}} left',
						description:
							'Informs the user that there are no licensed users left and they may need to upgrade their product',
					},
					{ product: getProductForTranslation(product) },
				),
				appearance: product !== 'confluence' ? 'danger' : 'warning',
				icon: 'warning',
			};
		}

		if (licensedUsers >= limits.warningUserLimit) {
			return {
				text: formatMessage(
					{
						id: '@atlassian/edition-awareness.button.jpd.limited-creators-left',
						defaultMessage:
							'Limited {product, select, jpd {creators} jsm {agents} jira {users} confluence {users} other {users}} left',
						description:
							'Informs the user that there are limited licensed users left before they need to upgrade their product',
					},
					{ product: getProductForTranslation(product) },
				),
				appearance: 'warning',
				icon: 'warning',
			};
		}
	}

	if (product === 'confluence' && edition === 'free' && licensedUsers && licensedUsers >= 3) {
		// Confluence has its own variety when compared to the `userLimits` feature
		return {
			text: formatMessage({
				id: '@atlassian/edition-awareness.button.upgrade',
				defaultMessage: 'Upgrade',
				description: 'Informs the user that they can upgrade their product',
			}),
			appearance: 'warning',
			icon: 'warning',
		};
	}

	return {
		text: formatMessage({
			id: '@atlassian/edition-awareness.button.upgrade',
			defaultMessage: 'Upgrade',
			description: 'Informs the user that they can upgrade their product',
		}),
		appearance: 'default',
		icon: 'gem',
	};
}

function getTrialContent({
	licensedUsers,
	storageUsageInGB,
	context,
	formatMessage,
}: {
	licensedUsers?: number | null;
	storageUsageInGB?: number;
	context: EditionAwarenessContextType;
	formatMessage: IntlShape['formatMessage'];
}): ButtonContent {
	const { product, trialDaysLeft } = context;

	let appearance: ButtonContent['appearance'] = 'default';
	let icon: ButtonContent['icon'] = 'gem';

	if (
		isEligibleForStandardTrialsUserAndStorageLimitBreakers({
			context,
			licensedUsers,
			storageUsageInGB,
		}) &&
		FeatureGates.getExperimentValue<ExperimentCohort>(
			product === 'jira'
				? 'jira_edition_awareness_standard_trials'
				: 'connie_edition_awareness_standard_trials',
			'cohort',
			'not-enrolled',
		) === 'treatment'
	) {
		appearance = 'warning';
		icon = 'warning';
	}

	return {
		text: formatMessage(
			{
				id: '@atlassian/edition-awareness.button.daysLeft',
				defaultMessage: '{trialDaysLeft} days left',
				description: 'Informs the user how many days they have left in trial',
			},
			{ trialDaysLeft },
		),
		appearance,
		icon,
	};
}

function getPredunningContent({
	licensedUsers,
	storageUsageInGB,
	context,
	formatMessage,
}: {
	licensedUsers?: number | null;
	storageUsageInGB?: number;
	context: EditionAwarenessContextType;
	formatMessage: IntlShape['formatMessage'];
}): ButtonContent {
	const { product } = context;

	let appearance: ButtonContent['appearance'] = 'default';
	let icon: ButtonContent['icon'] = 'credit-card';

	if (
		isEligibleForStandardTrialsUserAndStorageLimitBreakers({
			context,
			licensedUsers,
			storageUsageInGB,
		}) &&
		FeatureGates.getExperimentValue<ExperimentCohort>(
			product === 'jira'
				? 'jira_edition_awareness_standard_trials'
				: 'connie_edition_awareness_standard_trials',
			'cohort',
			'not-enrolled',
		) === 'treatment'
	) {
		appearance = 'warning';
		icon = 'warning';
	}

	return {
		text: formatMessage({
			id: '@atlassian/edition-awareness.button.addPaymentDetails',
			defaultMessage: 'Add payment details',
			description: 'Informs the user how many days they have left in trial',
		}),
		appearance,
		icon,
	};
}

export function useButtonContent({
	licensedUsers,
	storageUsageInGB,
}: {
	licensedUsers?: number | null;
	storageUsageInGB?: number;
}): ButtonContent | null {
	const { formatMessage } = useIntl();

	const context = useEditionAwareness();
	const { edition, isInPredunning, isInTrial } = context;

	if (edition === 'free') {
		return getFreeContent({ licensedUsers, context, formatMessage });
	}

	if (isInTrial) {
		return getTrialContent({ licensedUsers, storageUsageInGB, context, formatMessage });
	}

	if (isInPredunning) {
		return getPredunningContent({ licensedUsers, storageUsageInGB, context, formatMessage });
	}

	if (isEligibleForStandardToPremium(context)) {
		return {
			text: formatMessage({
				id: '@atlassian/edition-awareness.button.upgrade',
				defaultMessage: 'Upgrade',
				description: 'Informs the user that they can upgrade their product',
			}),
			appearance: 'default',
			icon: 'gem',
			isDismissible: true,
		};
	}

	// TODO: Feature Gate this, with proper eligibility criteria
	// if (edition === 'premium') {
	// 	return {
	// 		text: formatMessage({
	// 			id: '@atlassian/edition-awareness.button.premium-trial',
	// 			defaultMessage: 'Premium trial',
	// 			description: 'Informs the user that they are on a premium trial',
	// 		}),
	// 		appearance: 'default',
	// 		icon: 'gem',
	// 	};
	// }

	return null;
}
