import React, { useCallback } from 'react';

import { FormattedMessage } from 'react-intl-next';

import { Spotlight as SpotlightImpl, SpotlightTransition } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';

import { useEditionAwareness } from '../services/edition-awareness-context';
import { isEligibleForStandardToPremium } from '../services/is-eligible-for-standard-to-premium';

export function Spotlight() {
	const context = useEditionAwareness();
	const { product, isSpotlightActive, handleSetSpotlight } = context;

	const handleDismissSpotlight = useCallback(() => {
		handleSetSpotlight?.(false);
	}, [handleSetSpotlight]);

	if (isEligibleForStandardToPremium(context)) {
		const target = product === 'confluence' ? 'nav-profile' : 'profile-spotlight';

		return (
			<SpotlightTransition>
				{isSpotlightActive && (
					<SpotlightImpl
						actions={[
							{
								onClick: handleDismissSpotlight,
								text: 'Got it',
							},
						]}
						target={target}
						key={target}
						targetRadius={3}
						dialogWidth={275}
						targetBgColor={token('utility.elevation.surface.current')}
					>
						<FormattedMessage
							id="@atlassian/edition-awareness.spotlight.message"
							defaultMessage="Not ready to upgrade? Return to your profile when you're ready."
							description="Message to show in the spotlight on the profile menu icon when the user dismisses the EA pill and is eligible for standard to premium upsell"
						/>
					</SpotlightImpl>
				)}
			</SpotlightTransition>
		);
	}

	return null;
}
