import { useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { Edition, Product } from '../types';

import { ANALYTICS_CHANNEL, defaultAttributes, getAttributesFromContexts } from './use-analytics';

export function useErrorAnalytics({
	product,
	edition,
	id,
}: {
	product: Product;
	edition: Edition;
	id?: string;
}) {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleOnError = useCallback(
		(error: Error) => {
			const event = createAnalyticsEvent({
				eventType: 'operational',
				action: 'failed',
				actionSubject: '@atlassian/edition-awareness.edition-awareness',
				attributes: {
					...defaultAttributes,
					product,
					currentProductEdition: edition,
					error: error.message,
					errorStack: error.stack,
					errorName: error.name,
					id: id ?? 'unknownErrorId',
				},
			});

			const contextAttributes = getAttributesFromContexts(event.context);

			event
				.update({
					attributes: {
						...contextAttributes,
						...event.payload.attributes,
					},
				})
				.fire(ANALYTICS_CHANNEL);
		},
		[createAnalyticsEvent, id, product, edition],
	);

	return handleOnError;
}
